import * as React from "react";
import {useTranslation} from "gatsby-plugin-react-i18next";
import {Col, Container} from "react-bootstrap";
import {withPrefix} from "gatsby";

export default function FAQ({questionsData}) {
    const { t } = useTranslation();

    return (
        <section className="faq grad">
            <Container fluid className="position-relative px-flex">
                <Col xs={12} className="d-inline-block my-3 my-md-4 my-xxl-5 py-2 py-xl-3 py-xxl-4">
                    <div className="d-flex justify-content-center align-items-center">
                        <svg className="svg-icon mb-3 mb-xl-4 me-2 me-xl-3">
                            <use xlinkHref={withPrefix(`sprite.svg#faq-icon`)}></use>
                        </svg>
                        <h2 className="h1 mb-3 mb-xl-4 text-center">{t('faqTitle')}</h2>
                    </div>
                    <div itemscope itemtype="https://schema.org/FAQPage" className="d-flex justify-content-center row">
                        {questionsData.map((question, index) => {
                            return (
                                <Col xs={12} md={6} xl={4} xxl={3} className="my-2 my-md-3" key={`faq-item-${index}`}>
                                    <div className="faq__item"
                                         itemscope
                                         itemprop="mainEntity"
                                         itemtype="https://schema.org/Question"
                                    >
                                        <h3 className="faq__item-head mb-1" itemprop="name">{t(question.question)}</h3>
                                        <div className="faq__item-text" itemscope itemprop="acceptedAnswer"
                                             itemtype="https://schema.org/Answer">
                                            <div className="acc-text_inner" itemprop="text" dangerouslySetInnerHTML={{__html: t(question.answer)}} />
                                        </div>
                                    </div>
                                </Col>
                            )
                        })}
                    </div>
                </Col>
            </Container>
        </section>
    )
}