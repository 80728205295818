import * as React from "react"
import {graphql} from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import Slider from "react-slick";
import {createRef, useEffect} from "react";
import HeaderTitle from "../components/HeaderTitle";
import HowOrder from "../components/HowOrder";
import Reviews from "../components/Reviews";
import WhyOrder from "../components/WhyOrder";
import PortfolioBlock from "../components/PortfolioBlock";
import HomeContactForm from "../components/HomeContactForm";
import FAQ from "../components/FAQ";
import SupportBlock from "../components/SupportBlock";
import SeoBlock from "../components/SeoBlock";
import HomeBlog from "../components/blog/HomeBlog";

export default function IndexPage() {
    const { t } = useTranslation();
    const sliderRef = createRef();
    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToScroll: 1,
        afterChange: function(currentSlide) {
            sliderStatus(currentSlide);
        }

    };

    const questionsData = [
        {
            question: 'faqQuestion1Title',
            answer: 'faqQuestion1Text'
        },
        {
            question: 'faqQuestion2Title',
            answer: 'faqQuestion2Text'
        },
        {
            question: 'faqQuestion3Title',
            answer: 'faqQuestion3Text'
        },
        {
            question: 'faqQuestion4Title',
            answer: 'faqQuestion4Text'
        }
    ];

    useEffect(() => {
        sliderStatus(0);
    }, []);

    function sliderStatus(currentSlide, totalSlides = sliderRef.current.props.children.length) {
        const $sliderStatus = document.getElementsByClassName('full-screen-slider-status-inner');

        if($sliderStatus.length > 0) {
            $sliderStatus[0].style.width = `${(currentSlide + 1) / totalSlides * 100}%`;
        }
    }

    return (
        <Layout>
            <Seo title={t('homeSeoTitle')} description={t('homeSeoDescription')} />
            <section className="grad">
                <HeaderTitle />
                <HowOrder />
                <Slider className="full-screen-slider" ref={sliderRef} {...sliderSettings}>
                    <div className="full-screen-slider__item position-relative">
                        <div className="fake-block position-absolute w-100 h-100 px-2 d-flex justify-content-center align-items-center">
                            <div className="full-screen-slider__item-title text-center text-white">{t('homeSlide0Title')} ➜</div>
                        </div>
                    </div>
                    <div className="full-screen-slider__item position-relative">
                        <div className="full-screen-slider__item-bg w-100 h-100">
                            <StaticImage
                                src="../slider/artshowgcvetkov.png"
                                width={2560}
                                quality={95}
                                formats={["auto", "webp", "avif"]}
                                alt={t('homeSlide1Title')}
                                className="w-100"
                            />
                        </div>
                        <div className="fake-block position-absolute w-100 h-100 px-2 d-flex justify-content-center align-items-center">
                            <div className="full-screen-slider__item-title text-center text-white">{t('homeSlide1Title')}</div>
                        </div>
                    </div>
                    <div className="full-screen-slider__item position-relative">
                        <div className="full-screen-slider__item-bg w-100 h-100">
                            <StaticImage
                                src="../slider/prosteklo.png"
                                width={2600}
                                quality={95}
                                formats={["auto", "webp", "avif"]}
                                alt={t('homeSlide2Title')}
                                className="w-100"
                            />
                        </div>
                        <div className="fake-block position-absolute w-100 h-100 px-2 d-flex justify-content-center align-items-center">
                            <div className="full-screen-slider__item-title text-center text-white">{t('homeSlide2Title')}</div>
                        </div>
                    </div>
                    <div className="full-screen-slider__item position-relative">
                        <div className="full-screen-slider__item-bg w-100 h-100">
                            <StaticImage
                                src="../slider/artshowgcvetkov.png"
                                width={2560}
                                quality={95}
                                formats={["auto", "webp", "avif"]}
                                alt={t('homeSlide1Title')}
                                className="w-100"
                            />
                        </div>
                        <div className="fake-block position-absolute w-100 h-100 px-2 d-flex justify-content-center align-items-center">
                            <div className="full-screen-slider__item-title text-center text-white">{t('homeSlide3Title')}</div>
                        </div>
                    </div>
                </Slider>
            </section>
            <div className="full-screen-slider-status w-100 position-relative rounded mx-auto my-2 my-xl-3">
                <div className="full-screen-slider-status-inner grad position-absolute rounded" />
            </div>
            <PortfolioBlock isHome />
            <WhyOrder />
            <Reviews />
            <a className='yakor' name='zakazat-sayt'></a>
            <HomeContactForm headText="orderSite" />
            <SupportBlock icon="service-icon" head="homeSupportTitle" text="homeSupportText" />
            <SupportBlock icon="my-orders-icon" head="homeHowMuchTitle" text="homeHowMuchText" orientation="right" />
            <HomeBlog />
            <FAQ questionsData={questionsData} />
            <SeoBlock text="devText1" />
        </Layout>
    )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;