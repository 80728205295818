import * as React from "react";
import {useTranslation} from "gatsby-plugin-react-i18next";
import {Col, Container, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import {withPrefix} from "gatsby";
const FonSvg = '/svg/avzpro-fon.svg';
const BukvaSvg = '/svg/avzpro-bukva.svg';

export default function HeaderTitle() {
    const { t } = useTranslation();
    const [scrollTop, setScrollTop] = useState(0);
    const [imgKranHeight, setImgKranHeight] = useState(0);

    useEffect(() => {
        const handleScroll = event => {
            setScrollTop(window.scrollY / (
                document.getElementsByClassName( 'header-tittle__img-fon')[0].clientHeight / (window.innerWidth > 1199 ?
                    window.innerWidth > 1999 ?
                        200 : 80
                    : 20)
            ));
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        setImgKranHeight(document.getElementsByClassName( 'header-tittle__img-fon')[0].clientWidth);

        const imgKranHeight = () => {
            setImgKranHeight(document.getElementsByClassName( 'header-tittle__img-fon')[0].clientWidth);
        };

        window.addEventListener('resize', imgKranHeight);
        window.addEventListener('orientationchange', imgKranHeight);

        return () => {
            window.removeEventListener('resize', imgKranHeight);
            window.removeEventListener('orientationchange', imgKranHeight);
        }
    }, []); // Will fire only once

    return (
        <Container fluid className="header-tittle px-flex">
            <Row className="mt-2 mt-xl-3 pt-2 pt-xl-3">
                <Col md={8} xxl={7} className="mb-3 mb-xl-0">
                    <h2 className="header-tittle__h1 d-flex justify-content-center align-items-center mb-4 pb-xl-1"
                        dangerouslySetInnerHTML={{__html: t('headerTitle')}} />
                    <div className="d-flex justify-content-center align-items-center mb-3 mb-xl-5 pb-3 pb-xxl-2 flex-wrap flex-xl-nowrap">
                        <div className="d-flex mb-4 mb-xl-0 pb-3 pb-xl-0">
                            <div className="d-flex flex-wrap justify-content-center align-items-center">
                                <svg className="header-tittle__icon mb-2 mb-xl-3">
                                    <use xlinkHref={withPrefix(`sprite.svg#created-icon`)}></use>
                                </svg>
                                <div className="header-tittle__icon-text w-100 px-2 pt-1 text-center">{t('headerTitleIconText')}</div>
                            </div>
                            <div className="header-tittle__adaptive d-flex flex-wrap justify-content-center align-items-center">
                                <svg className="header-tittle__icon mb-2 mb-xl-3">
                                    <use xlinkHref={withPrefix(`sprite.svg#adaptive-icon`)}></use>
                                </svg>
                                <div className="header-tittle__icon-text w-100 px-2 pt-1 text-center">{t('headerTitleIconText2')}</div>
                            </div>
                        </div>
                        <a className="btn btn__big white-btn text-nowrap ms-xl-3" href="#zakazat-sayt">{t('orderSite')}</a>
                    </div>
                    <div className="header-tittle__content mx-xxl-auto" dangerouslySetInnerHTML={{__html: t('headerTitleText')}} />
                </Col>
                <Col md={4} xxl={5}>
                    <div className="header-tittle__img position-sticky w-75 mx-auto">
                        <div className="position-relative">
                            <img className="header-tittle__img-fon w-100" width={1000} height={867} placeholder="blurred" src={FonSvg} />
                            <img
                                src={BukvaSvg}
                                width={1000}
                                height={523}
                                alt="Адаптивная вёрстка в подарок"
                                className="header-tittle__img-bukva w-100 position-absolute"
                                style={{top: `${scrollTop < imgKranHeight - imgKranHeight / 1.4 ? scrollTop : imgKranHeight - imgKranHeight / 1.4}px`}}
                                placeholder="blurred"
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}